//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-772:_5188,_4668,_3584,_5140,_780,_5476,_3600,_5572;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-772')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-772', "_5188,_4668,_3584,_5140,_780,_5476,_3600,_5572");
        }
      }catch (ex) {
        console.error(ex);
      }